<template>
  <header-page :title="'Analytics'" :hasBackLink="false" />

  <el-scrollbar :always="true">
    <div class="analytics-wrapper">
      <div
        class="analytics-cell"
        v-for="item in listTables"
        :key="item.key"
        :style="`order:${item.priority}`"
        v-loading="item.isLoading"
      >
        <el-scrollbar :always="true">
          <AnalyticsTableComp
            v-model="item.filters"
            :tableParams="item.tableParams"
            :filtersMetaDataProp="item.filtersMetaData"
            :tableKey="item.key"
            @updateTable="updateTable"
            :isMaximized="item.isMaximized"
            @maximize="maximizeTable"
            :getSummaries="getSummaries"
            :isNeedCustomSum="isNeedCustomSum(item.key)"
          />
        </el-scrollbar>

        <el-dialog
          v-model="item.isMaximized"
          :destroy-on-close="true"
          :width="item.dialogWidth"
          top="10vh"
        >
          <AnalyticsTableComp
            v-model="item.filters"
            :tableParams="item.tableParams"
            :filtersMetaDataProp="item.filtersMetaData"
            :tableKey="item.key"
            :isMaximized="item.isMaximized"
            @updateTable="updateTable"
            @maximize="maximizeTable"
            :isOpenModal="true"
          />
        </el-dialog>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { urls, fetchData } from '@/utils/urls.js'
import HeaderPage from '@/components/HeaderPage.vue'
import AnalyticsTableComp from '@/components/AnalyticsTable.vue'

const columnsKeysToFormat = {
  3: [
    'undefined',
    'scheduled',
    'pitched',
    'negotiation',
    'published',
    'declined',
    'failed',
    'no_longer_a_partner',
    'no_longer_a_client',
    'not_active_site',
    'recalled',
    'removed',
    'error_404',
    'sponsored',
    'ugc_tag',
    'no_index',
    'no_follow',
    'no_canonical',
    'total',
  ],
  4: ['published', 'total'],
  5: ['published', 'invoices', 'progress'],
}

function textFormated(tableKey, key, value) {
  if (
    tableKey in columnsKeysToFormat &&
    columnsKeysToFormat[tableKey].includes(key)
  )
    return `$${value}`
  return value
}

function getSummaries(param) {
  const { columns, data } = param

  const sums = []
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = 'Total'
      return
    }
    const values = data.map((item) => Number(item[column.property]))

    // if (
    //   tableKey in columnsKeysToFormat &&
    //   columnsKeysToFormat[tableKey].includes(key)
    // )
    if (!values.every((value) => Number.isNaN(value))) {
      sums[index] = `${'$'}${values.reduce((prev, curr) => {
        const value = Number(curr)
        if (!Number.isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)}`
    } else {
      sums[index] = 'N/A'
    }
  })

  return sums
}

class AnalyticsTableParams {
  title = null
  rowKey = 'name'
  columns = []
  rows = []

  constructor(data) {
    for (let key in data) {
      if (this[key] === undefined) {
        console.error(
          `Key ${key} is not defined in the class AnalyticsTableParams`
        )
        continue
      }
      this[key] = data[key]
    }
  }
}

class RequestData {
  url = null
  type = 'POST'

  constructor(data) {
    for (let key in data) {
      if (this[key] !== undefined) {
        this[key] = data[key]
      }
    }
  }
}

class FiltersMetadata {
  key = null
  label = null
  type = null
  values = []

  constructor(data) {
    for (let key in data) {
      if (this[key] !== undefined) {
        this[key] = data[key]
      }
    }
  }
}

class AnalyticsTable {
  dialogWidth = '50%'
  isLoading = false
  isMaximized = false
  requestData = null
  priority = 0
  key = null
  filtersMetaData = null
  filters = null
  tableParams = null

  constructor(data) {
    for (let key in data) {
      if (this[key] === undefined) {
        console.error(`Key ${key} is not defined in the class`)
        continue
      }
      if (key === 'requestData') this[key] = new RequestData(data[key])
      else if (key === 'filtersMetaData' && this[key]?.length) {
        this[key] = data[key].map((item) => new FiltersMetadata(item))
      } else this[key] = data[key]
    }
  }
}

// q1 (для Quarters: Q1)
// q2 (для Quarters: Q2)
// q3 (для Quarters: Q3)
// q4 (для Quarters: Q4)
// cm (для current month)
// cq (для current quarter)
// cy (для current year)

const filtersMetaDataDefault = [
  new FiltersMetadata({
    key: 'details_f',
    label: 'Details',
    type: 'switch',
  }),

  new FiltersMetadata({
    key: 'date_f',
    label: 'Quarters',
    type: 'segmented',
    values: [
      {
        value: 'q1',
        label: 'Q1',
      },
      {
        value: 'q2',
        label: 'Q2',
      },
      {
        value: 'q3',
        label: 'Q3',
      },
      {
        value: 'q4',
        label: 'Q4',
      },
    ],
  }),

  new FiltersMetadata({
    key: 'date_f',
    label: 'Current',
    type: 'segmented',
    values: [
      {
        value: 'cm',
        label: 'Month',
      },
      {
        value: 'cq',
        label: 'Quarter',
      },
      {
        value: 'cy',
        label: 'Year',
      },
    ],
  }),

  new FiltersMetadata({
    key: 'date_month',
    label: 'Month',
    type: 'month',
  }),

  new FiltersMetadata({
    key: 'date_year',
    label: 'Year',
    type: 'year',
  }),
]

const filtersMetaDataLinkBuilder = new FiltersMetadata({
  key: 'link_builder_f',
  label: 'Link Builder',
  type: 'select',
  values: [],
})

export default {
  name: 'Analytics',
  components: { HeaderPage, AnalyticsTableComp },
  data() {
    return {
      listTables: [
        new AnalyticsTable({
          dialogWidth: '90%',
          isLoading: false,
          isMaximized: false,
          requestData: {
            url: urls.analyticsAllLinks,
            type: 'POST',
          },
          priority: 1,
          key: '1',
          filtersMetaData: [...filtersMetaDataDefault],
          filters: {
            date_f: 'cq',
            details_f: true,

            date_month: null, // do not send to the server
            date_year: null, // do not send to the server
          },
          tableParams: new AnalyticsTableParams({
            title: 'All Links',
            rowKey: 'name',
            columns: [],
            rows: [],
          }),
        }),

        new AnalyticsTable({
          dialogWidth: '90%',
          isLoading: false,
          isMaximized: false,
          requestData: {
            url: urls.analyticsCountClientAcquiredLinks,
            type: 'POST',
          },
          priority: 2,
          key: '2',
          filtersMetaData: [...filtersMetaDataDefault],
          filters: {
            date_f: 'cm',
            details_f: false,

            date_month: null, // do not send to the server
            date_year: null, // do not send to the server
          },
          tableParams: new AnalyticsTableParams({
            title: 'Count of Client links',
            rowKey: 'name',
            columns: [],
            rows: [],
          }),
        }),

        new AnalyticsTable({
          dialogWidth: '90%',
          isLoading: false,
          isMaximized: false,
          requestData: {
            url: urls.analyticsSumClientsLinks,
            type: 'POST',
          },
          priority: 3,
          key: '3',
          filtersMetaData: [...filtersMetaDataDefault],
          filters: {
            date_f: 'cm',
            details_f: false,

            date_month: null, // do not send to the server
            date_year: null, // do not send to the server
          },
          tableParams: new AnalyticsTableParams({
            title: 'Sum of client links',
            rowKey: 'name',
            columns: [],
            rows: [],
          }),
        }),

        new AnalyticsTable({
          dialogWidth: '40%',
          isLoading: false,
          isMaximized: false,
          requestData: {
            url: urls.analyticsSumPublishedLinks,
            type: 'POST',
          },
          priority: 4,
          key: '4',
          filtersMetaData: [...filtersMetaDataDefault],
          filters: {
            date_f: 'cy',
            details_f: true,

            date_month: null, // do not send to the server
            date_year: null, // do not send to the server
          },
          tableParams: new AnalyticsTableParams({
            title: 'Sum of all published links',
            rowKey: 'name',
            columns: [],
            rows: [],
          }),
        }),

        new AnalyticsTable({
          dialogWidth: '40%',
          isLoading: false,
          isMaximized: false,
          requestData: {
            url: urls.analyticsClientBudgetProgress,
            type: 'POST',
          },
          priority: 5,
          key: '5',
          filtersMetaData: [],
          filters: {},
          tableParams: new AnalyticsTableParams({
            title: 'Clients Budget Progress',
            rowKey: 'name',
            columns: [],
            rows: [],
          }),
        }),

        new AnalyticsTable({
          dialogWidth: '45%',
          isLoading: false,
          isMaximized: false,
          requestData: {
            url: urls.analyticsFailedLinksLinkBuilder,
            type: 'POST',
          },
          priority: 6,
          key: '6',
          filtersMetaData: [...filtersMetaDataDefault],
          filters: {
            date_f: 'cq',
            details_f: true,
            link_builder_f: null,

            date_month: null, // do not send to the server
            date_year: null, // do not send to the server
          },
          tableParams: new AnalyticsTableParams({
            title: 'Failed Links by Link Builder',
            rowKey: 'name',
            columns: [],
            rows: [],
          }),
        }),

        new AnalyticsTable({
          dialogWidth: '45%',
          isLoading: false,
          isMaximized: false,
          requestData: {
            url: urls.analyticsStatusesLinksByLinkbuilder,
            type: 'POST',
          },
          priority: 7,
          key: '7',
          filtersMetaData: [...filtersMetaDataDefault],
          filters: {
            date_f: 'cq',
            details_f: true,
            link_builder_f: null,

            date_month: null, // do not send to the server
            date_year: null, // do not send to the server
          },
          tableParams: new AnalyticsTableParams({
            title: 'Published and In Progress Links by Link Builder',
            rowKey: 'name',
            columns: [],
            rows: [],
          }),
        }),

        new AnalyticsTable({
          dialogWidth: '45%',
          isLoading: false,
          isMaximized: false,
          requestData: {
            url: urls.analyticsStuckLinks,
            type: 'POST',
          },
          priority: 9,
          key: '9',
          filtersMetaData: [...filtersMetaDataDefault],
          filters: {
            date_f: 'cq',
            details_f: true,
            link_builder_f: null,

            date_month: null, // do not send to the server
            date_year: null, // do not send to the server
          },
          tableParams: new AnalyticsTableParams({
            title: 'Stuck Links',
            rowKey: 'name',
            columns: [],
            rows: [],
          }),
        }),
      ],
    }
  },

  async created() {
    await this.fetchLinkBuilder()
    filtersMetaDataLinkBuilder.values = await this.fetchLinkBuilder()
    this.listTables[5].filtersMetaData.push(filtersMetaDataLinkBuilder)
    this.listTables[6].filtersMetaData.push(filtersMetaDataLinkBuilder)
    this.listTables[7].filtersMetaData.push(filtersMetaDataLinkBuilder)
    await this.fetchAnalytics()
  },

  methods: {
    getSummaries,
    isNeedCustomSum(tableKey) {
      return tableKey in columnsKeysToFormat
    },

    async fetchLinkBuilder() {
      let query = `page=1&per_page=all&email=asc`
      let result = await fetchData(urls.users, 'GET', null, query)

      return (result?.rows || []).map((item) => {
        return {
          value: item.email,
          label: item.email,
        }
      })
    },

    maximizeTable(tableKey, isMaximized) {
      const table = this.listTables.find((item) => item.key === tableKey)
      if (!table) return
      table.isMaximized = isMaximized
    },

    updateTable(tableKey) {
      const table = this.listTables.find((item) => item.key === tableKey)
      if (!table) return
      this.fetchOneAnaleticsTable(table)
    },

    bodyToRequestData(filters) {
      let result = {}
      for (const key in filters) {
        if (key === 'date_month' && filters[key]) {
          const lastDayOfMonth = new Date(filters[key])
          lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1)
          lastDayOfMonth.setDate(0)

          result = {
            ...result,
            ...{
              date_f_from: filters[key],
              date_f_to: lastDayOfMonth.toISOString().split('T')[0],
            },
          }
        } else if (key === 'date_year' && filters[key]) {
          const lastDayOfYear = new Date(filters[key])
          lastDayOfYear.setFullYear(lastDayOfYear.getFullYear() + 1)
          lastDayOfYear.setDate(0)

          result = {
            ...result,
            ...{
              date_f_from: filters[key],
              date_f_to: lastDayOfYear.toISOString().split('T')[0],
            },
          }
        } else if (['date_month', 'date_year'].includes(key)) continue
        else result = { ...result, [key]: filters[key] }
      }

      for (const key in result) if (result[key] === null) delete result[key]

      return result
    },

    async fetchAnalytics() {
      await Promise.all(this.listTables.map(this.fetchOneAnaleticsTable))
    },

    async fetchOneAnaleticsTable(table) {
      table.isLoading = true
      table.tableParams.rows = []
      let result = await fetchData(
        table.requestData.url,
        table.requestData.type,
        this.bodyToRequestData(table.filters)
      )

      if (result?.detail) return null

      if (result) {
        if (result?.columns) {
          table.tableParams.columns = result.columns.map((item, index) => {
            return {
              key: item.key,
              text: item.text,
              width: !index ? '150' : '70',
              textFormated: textFormated,
            }
          })
        }

        if (result?.rows) {
          const addHasChildrenProperty = (arr) => {
            arr.forEach((item) => {
              if (item?.children?.length) {
                item.hasChildren = false
                addHasChildrenProperty(item.children)
              }
            })
          }

          addHasChildrenProperty(result.rows)
          table.tableParams.rows = result.rows
        }
      }

      table.isLoading = false
      return result
    },
  },
}
</script>

<style lang="scss">
.analytics-wrapper {
  display: flex;
  flex-wrap: wrap;
  //min-height: calc(100vh - 150px);
  margin: 10px 10px 150px 10px;
  gap: 20px;

  .analytics-cell {
    width: calc(33.333% - 20px);
    padding: 5px;
    max-height: calc(100vh - 250px);
    box-shadow: var(--el-box-shadow-light);
    box-sizing: border-box;
    border-radius: 12px;
  }
}
</style>
